import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Button, DataGrid, IDataGridLayout, EmptyState } from "ktm-ui";
import Cost_centreNewScreen from "planadmin/cost_centre/New";
import Cost_centreRecordScreen from "planadmin/cost_centre/Record";

interface IdsCostCentres {
  Id: string;
  Policy_Id: string;
  Name: string;
  Description: string;
  IsActive: boolean;
}

export default KTMScreen(
  { name: "planadmin.policy.cost-centres" },
  class extends React.Component<{ policy_id: string }> {
    data: {
      dsCostCentres: IdsCostCentres[];
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsCostCentres";
      await KTMApi.start(screen, {
        "@policy_id": this.props.policy_id,
      });
      screen.title = "Cost centres";
    }

    main({ field, draw, action }: RenderEvent) {
      return <Layout>{draw(this.default_view)}</Layout>;
    }

    default_view({ field, draw, action, collection }: RenderEvent) {
      let actions = (
        <>
          <Button
            action={action(this.add)}
            label="Add cost centre"
            style="add"
          />
        </>
      );
      let layout: IDataGridLayout = {
        columns: [
          { name: "Name", label: "Name", type: "character" },
          {
            name: "Description",
            label: "Description",
            type: "character",
            width: 400,
          },
          { name: "IsActive", label: "Is Active", type: "logical" },
        ],
        sort: [{ name: "Name" }],
      };
      return (
        <DataGrid
          collection={collection("@dsCostCentres")}
          layout={layout}
          onRowClick={action(this.open_row)}
          actions={actions}
        />
      );
    }

    empty_state({ field, draw, action }: RenderEvent) {
      return (
        <EmptyState label="Welcome to cost centres!" icon="bill">
          <div style={{ width: "50%", textAlign: "center" }}>
            <div style={{ marginBottom: 15 }}>
              Typically a cost centre is a department, position or function
              within an organization that does not directly add to profit but
              still costs the organization money to operate. Examples include:
              accounting, human resources, IT maintenance, and more.
            </div>
            <div style={{ marginBottom: 25 }}>
              If you want to tag and group employees by cost centres in your
              reports, create your cost centres on this page. Then when you add
              new employees, you can tag them with a cost centre, as needed. For
              existing employees, you can edit their profile and tag them with a
              cost centre after it has been created here.
            </div>
          </div>
          <Button
            action={action(this.add)}
            label="Add cost centre"
            style="primary"
          />
        </EmptyState>
      );
    }

    async search({ screen }: ActionEvent) {
      await KTMApi.action(screen, "search");
    }

    async add({ screen }: ActionEvent) {
      await screen.openDialog(
        <Cost_centreNewScreen policy_id={this.props.policy_id} />,
        { onRecordChanged: this.refresh_row }
      );
    }

    async open_row({ screen }: ActionEvent) {
      screen.browse(
        <Cost_centreRecordScreen costcentre_id={screen.getValue("Id")} />,
        { label: "Cost centre", onRecordChanged: this.refresh_row }
      );
    }

    async refresh_row({ screen, eventValue }: ActionEvent) {
      screen.showLayout("main");
      await KTMApi.refreshRow(screen, {
        collection: "@dsCostCentres",
        rowid: eventValue,
      });
    }
  }
);
