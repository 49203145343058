import { Component } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import toastr from 'toastr';
import { CustomDropdown } from "components/CustomDropdown";
import { Line } from 'ktm-ui';

export default class BulkEditEmployeeBenCatCampaignModal extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.props.bencatOptions[0],
            saveCompleted: false,
            showEmailPrompt: false,
        };
    }
    
    handleSave = () => {
        var body = {
            policyId: this.props.policyId,
            employeeIds: this.props.employeeIds,
            selectedBenCatId: this.state.selectedOption.id,
        };

        axios({
            method: "put",
            url: "/api/Flex/updateemployeebencatcampaign",
            data: body,
        })
        .then((response) => {
            const today = new Date();
            if (response.data.success) {
                toastr.success("Employee's campaign updated successfully", "Success");
                if (this.state.selectedOption.isFlex) {
                    if (new Date(this.props.campaignLaunchDate) <= today){
                        this.setState({ showEmailPrompt: true });
                    }
                    this.setState({ saveCompleted: true });
                } else {
                    this.props.closeBulkEditEmployeeBenCatCampaignModal(true);
                }
            } else {
                toastr.error(response.data.message, "Error");
                this.props.closeBulkEditEmployeeBenCatCampaignModal(false);
            }
        })
        .catch(function (error) {
            toastr.error("Failed to update employee's campaign", "Error");
        });
    };

    handleSendEmail = () => {
        var body = {
            PolicyId: this.props.policyId,
            RecipientIds: this.props.employeeIds,
        };
       
        axios({
            method: "put",
            url: "/api/Flex/sendselectionemails",
            data: body,
        })
        .then((response) => {
            if (response.data.success) {
                toastr.success("Email sent successfully", "Success");
            } else {
                toastr.error(response.data.message, "Error");
            }
        })
        .catch(function (error) {
            toastr.error("Failed to send email", "Error");
        });

        this.setState({ saveCompleted: false });
        this.props.closeBulkEditEmployeeBenCatCampaignModal(true);
    }

    handleCloseEmailModal = () => {
        this.setState({ saveCompleted: false });
        this.props.closeBulkEditEmployeeBenCatCampaignModal(true);
    }

    handleSelect = (e: any) => {
        this.setState({ selectedOption: e.target.value });
    }

    render() {
        const { 
            showBulkEditEmployeeBenCatCampaignModal, 
            bencatOptions, 
            closeBulkEditEmployeeBenCatCampaignModal,
        } = this.props;

        const { 
            selectedOption,
            saveCompleted,
            showEmailPrompt
        } = this.state;
        
        const editBenCatModal = (
            <Modal className="edit-employee-campaign-modal-container"
                   show={showBulkEditEmployeeBenCatCampaignModal && !showEmailPrompt}>
                <Modal.Header className="campaign-edit-employee-modal-header">
                    <Modal.Title>Edit Employee Campaign</Modal.Title>
                </Modal.Header>
                <Modal.Body className="campaign-edit-employee-modal-body">
                    <Card>
                        <Line>
                            <div className="RT-TextBox">
                                <label className="RT-Label">Benefit Category</label>
                                <CustomDropdown
                                    options={bencatOptions.map((option) => { return option.name })}
                                    selectedOption={selectedOption.name}
                                    setSelectedOption={(option) => {
                                        this.setState({ selectedOption: bencatOptions.filter((bencat) => bencat.name === option)[0] });
                                    }}
                                />
                            </div>
                        </Line>
                    </Card>
                </Modal.Body>
                <Modal.Footer className="campaign-edit-employee-modal-footer">
                    <Button variant="secondary" onClick={closeBulkEditEmployeeBenCatCampaignModal}>Close</Button>
                    <Button variant="primary" onClick={this.handleSave}>Save</Button>
                </Modal.Footer>
            </Modal>
        );
        
        const sendEmailModal = (
            <Modal className="edit-employee-campaign-modal-container" show={showEmailPrompt}>
                <Modal.Header className="campaign-edit-employee-modal-header">
                    <Modal.Title>Edit Employee Campaign</Modal.Title>
                </Modal.Header>
                <Modal.Body className="campaign-edit-employee-modal-body">
                    <Card>
                        <p style={{fontWeight: 'bold', color: 'red'}}>Do you want to send a flex email to
                            the employees?</p>
                    </Card>
                </Modal.Body>
                <Modal.Footer className="campaign-edit-employee-modal-footer">
                    <Button variant="secondary" onClick={this.handleCloseEmailModal}>No</Button>
                    <Button variant="primary" onClick={this.handleSendEmail}>Yes</Button>
                </Modal.Footer>
            </Modal>  
        );
        
        return (
            !saveCompleted ? editBenCatModal : showEmailPrompt ? sendEmailModal : <></>
        );
    }
}
