const CustomPagination = ({nPages, currentPage, setCurrentPage}) => {
    const pageNumbers = [];
    for (let i = 1; i <= nPages; i++) {
        pageNumbers.push(i);
    }

    const trimPageNumbers = () => {
        let start = Math.max(currentPage - 2, 2);
        let end = Math.min(start + 3, nPages - 1);
        start = Math.max(end - 3, 2);
        return pageNumbers.slice(start - 1, end);
    }

    const nextPage = () => {
        if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }

    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }

    const firstPage = () => {
        setCurrentPage(1)
    }
    
    const lastPage = () => {
        setCurrentPage(nPages)
    }

    return (
        <nav className="custom-pagination">
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <button className="page-link" onClick={firstPage}>
                        {"<<"}
                    </button>
                </li>
                <li className="page-item">
                    <button className="page-link" onClick={prevPage}>
                        {"<"}
                    </button>
                </li>
                <li className="page-item">
                    <button onClick={() => setCurrentPage(1)} className={`page-link ${currentPage == 1 ? 'active' : ''}`}>
                        {1}
                    </button>
                </li>
                {nPages > 1 && (
                    <>
                        {currentPage > 3 && (
                            <li className="page-item">
                                <button className="page-link" disabled>
                                    {"..."}
                                </button>
                            </li>
                        )}
                        {trimPageNumbers().map(pgNumber => (
                            <li key={pgNumber} className= "page-item" >
                                <button onClick={() => setCurrentPage(pgNumber)}  className={`page-link ${currentPage == pgNumber ? 'active' : ''} `}>
                                    {pgNumber}
                                </button>
                            </li>
                        ))}
                        {currentPage < nPages - 2 && (
                            <li className="page-item">
                                <button className="page-link" disabled>
                                    {"..."}
                                </button>
                            </li>
                        )}
                        <li className="page-item">
                            <button onClick={() => setCurrentPage(nPages)} className={`page-link ${currentPage == nPages ? 'active' : ''}`}>
                                {nPages}
                            </button>
                        </li>
                    </>
                )}
                <li className="page-item">
                    <button className="page-link" onClick={nextPage}>
                        {">"}
                    </button>
                </li>
                <li className="page-item">
                    <button className="page-link" onClick={lastPage}>
                        {">>"}
                    </button>
                </li>
            </ul>
        </nav>
    )
}

export default CustomPagination