import { Component } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import toastr from 'toastr';
import { Line } from 'ktm-ui';
import DatePicker from 'react-date-picker';
import moment from 'moment';

export default class BuldEditEmployeeDeadlineCampaignModal extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            selectionDeadline: this.props.selectionDeadline,
            saveCompleted: false,
        };
    }

    handleSave = () => {
        let selectionDate = new Date(this.state.selectionDeadline);

        var body = {
            policyId: this.props.policyId,
            employeeIds: this.props.employeeIds,
            newSelectionDealine: selectionDate.toLocaleDateString(),
        };

        axios({
            method: "put",
            url: "/api/Flex/updateemployeedeadlinecampaign",
            data: body,
        })
        .then((response) => {
            if (response.data.success) {
                toastr.success("Employee's campaign updated successfully", "Success");
                this.setState({ saveCompleted: true });
            } else {
                toastr.error(response.data.message, "Error");
                this.props.closeBulkEditEmployeeDeadlineCampaignModal(false);
            }
        })
        .catch(function (error) {
            toastr.error("Failed to update employee's campaign", "Error");
        });
    };

    handleSendEmail = () => {
        var body = {
            PolicyId: this.props.policyId,
            RecipientIds: this.props.employeeIds,
        };
       
        axios({
            method: "put",
            url: "/api/Flex/sendselectionemails",
            data: body,
        })
        .then((response) => {
            if (response.data.success) {
                toastr.success("Email sent successfully", "Success");
            } else {
                toastr.error(response.data.message, "Error");
            }
        })
        .catch(function (error) {
            toastr.error("Failed to send email", "Error");
        });

        this.setState({ saveCompleted: false });
        this.props.closeBulkEditEmployeeDeadlineCampaignModal(true);
    }

    handleCloseEmailModal = () => {
        this.setState({ saveCompleted: false });
        this.props.closeBulkEditEmployeeDeadlineCampaignModal(true);
    }

    handleDateChange = (e: any, arg1: string) => {
        switch (arg1) {
            case "SelectionDeadline":
                this.setState({ selectionDeadline: e });
                break;
        }
    }

    render() {
        const { 
            showBulkEditEmployeeDeadlineCampaignModal,
            closeBulkEditEmployeeDeadlineCampaignModal
        } = this.props;

        const { 
            selectionDeadline,
            saveCompleted,
        } = this.state;

        const today = new Date();
        const hasLaunched = new Date(this.props.campaignLaunchDate) <= today;
        
        const editSelectionDateModal = (<div>
            <Modal className="edit-employee-campaign-modal-container"
                   show={showBulkEditEmployeeDeadlineCampaignModal}>
                <Modal.Header className="campaign-edit-employee-modal-header">
                    <Modal.Title>Edit Employee Campaign</Modal.Title>
                </Modal.Header>
                <Modal.Body className="campaign-edit-employee-modal-body">
                    <Card>
                        <Line>
                            <div className="date-picker-container">
                                <label className="RT-Label">Selection Deadline</label>
                                <DatePicker
                                    onChange={(e) => {
                                        this.handleDateChange(e, "SelectionDeadline");
                                    }}
                                    value={new Date(selectionDeadline)}
                                    format={"y-MM-dd"}
                                    minDate={new Date()}
                                    clearIcon={null}
                                />
                            </div>
                        </Line>
                    </Card>
                </Modal.Body>
                <Modal.Footer className="campaign-edit-employee-modal-footer">
                    <Button variant="secondary"
                            onClick={closeBulkEditEmployeeDeadlineCampaignModal}>Close</Button>
                    <Button variant="primary" onClick={this.handleSave}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>);
        
        const sendEmailModal = (
            <div>
                <Modal className="edit-employee-campaign-modal-container"
                       show={showBulkEditEmployeeDeadlineCampaignModal}>
                    <Modal.Header className="campaign-edit-employee-modal-header">
                        <Modal.Title>Edit Employee Campaign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="campaign-edit-employee-modal-body">
                        <Card>
                            <p style={{fontWeight: 'bold', color: 'red'}}>Do you want to send a flex email to
                                the employees?</p>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer className="campaign-edit-employee-modal-footer">
                        <Button variant="secondary" onClick={this.handleCloseEmailModal}>No</Button>
                        <Button variant="primary" onClick={this.handleSendEmail}>Yes</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )

        return (
            !saveCompleted ? editSelectionDateModal : hasLaunched ? sendEmailModal : <></>
        );
    }
}
