import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Dialog, Button, Column, Panel, Display, Card } from "ktm-ui";
import CustomToolTip from "../../components/CustomToolTip";

interface IdsBenefit {
  Id: string;
  Policy_Id: string;
  BenefitType_Id: string;
  Name: string;
  Description: string;
}

export default KTMScreen(
  { name: "enroll.product.new" },
  class extends React.Component<{ policy_id: string }> {
    data: {
      dsBenefit: IdsBenefit;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@policy_id": this.props.policy_id,
      });
      screen.title = "Add product";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Add product" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
          />
        </Dialog.Footer>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
        </Panel>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      this.data.dsBenefit.Name = "Spending Account";
      return (
        <Card>
          <Display
            field={field("BenefitType_Id")}
            toolTip={
              <CustomToolTip
                header="Benefit Type"
                bodyContent="The type of benefit you are setting up. Spending Account is the only option until premium products (CH+, Insurpak) become available to offer on our new system."
              />
            }
          />
          <Display field={field("Name")} />
        </Card>
      );
    }

    async get_benefit_name({ screen }: ActionEvent) {
      await KTMApi.action(screen, "get_benefit_name", {
        "@dsBenefit.Name": this.data.dsBenefit.Name,
        "@dsBenefit.BenefitType_Id": this.data.dsBenefit.BenefitType_Id,
      });
    }

    async submit({ screen }: ActionEvent) {
      await KTMApi.action(screen, "submit", {
        "@policy_id": this.props.policy_id,
        "@dsBenefit.BenefitType_Id": this.data.dsBenefit.BenefitType_Id,
        "@dsBenefit.Name": this.data.dsBenefit.Name,
        "@dsBenefit.Description": this.data.dsBenefit.Description,
      });
      screen.close({ continue: true });
    }
  }
);
