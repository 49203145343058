import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Card, Line } from 'ktm-ui';

const AdvisorDetailsModal = ({ selectedItem, showModal, onHide }) => {
  if (!selectedItem) return null;

  return (
        <Modal 
          show={showModal} 
          onHide={onHide}
          className="Account-Details-Modal"
        >
          <Modal.Header>
            <Modal.Title style={{fontSize: "17px", fontWeight: 500 }}>Advisor Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedItem && (
              <Card>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Health Claim Account ID</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.healthClaimAccountId == null ? "N/A" : selectedItem.healthClaimAccountId}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Health Claim Advisor ID</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.healthClaimDistroId == null ? "N/A" : selectedItem.healthClaimAdvisorId}
                      </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Health Claim Relationship</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.healthClaimRelationship == null ? "N/A" : selectedItem.healthClaimRelationship}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Health Claim Description</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.healthClaimDescription == null ? "N/A" : selectedItem.healthClaimDescription}
                        </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Last Name</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.lastName == null ? "N/A" : selectedItem.lastName}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">First Name</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.firstName == null ? "N/A" : selectedItem.firstName}
                        </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing Street 1</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingStreet1 == null ? "N/A" : selectedItem.mailingStreet1}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing Street 2</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingStreet2 == null ? "N/A" : selectedItem.mailingStreet2}
                        </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing City</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingCity == null ? "N/A" : selectedItem.mailingCity}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing Province</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingProv == null ? "N/A" : selectedItem.mailingProv}
                        </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing Country</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingCountry == null ? "N/A" : selectedItem.mailingCountry}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing Postal Code</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingPostalCode == null ? "N/A" : selectedItem.mailingPostalCode}
                        </div>
                    </div>    
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Phone</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.phone == null ? "N/A" : selectedItem.phone}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Email</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.email == null ? "N/A" : selectedItem.email}
                        </div>
                    </div>
                  </Line>
                  <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Status</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.status == null ? "N/A" : selectedItem.status}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Is Owner</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.isActive  ? "Yes" : "No"}
                      </div>
                    </div>
                  </Line>
                    <Line>
                        <div className="RT-Static-Field">
                            <label className="RT-Static-Field__label">Is Active</label>
                            <div className="RT-Static-Field__content">
                                {selectedItem.isActive  ? "Yes" : "No"}
                            </div>
                        </div>
                        <div className="RT-Static-Field">
                            <label className="RT-Static-Field__label">User ID</label>
                            <div className="RT-Static-Field__content">
                                {selectedItem.user_Id == null ? "N/A" : selectedItem.user_Id}
                            </div>
                        </div>
                    </Line>
              </Card>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button 
              variant="secondary" 
              onClick={onHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
  );
};

export default AdvisorDetailsModal;
