import React from "react";
import {
  KTMScreen,
  ActionEvent,
  RenderEvent,
  renderScreen,
  Line,
} from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  RecordHeader,
  TabSet,
  Tab,
  Display,
  Button,
  Column,
  Panel,
  EmptyState,
} from "ktm-ui";
import BenefitsScreen from "./Benefits";
import EditScreen from "./Edit";

interface IdsBenCat {
  Id: string;
  Name: string;
  Description: string;
  Policy_Id: string;
  IsHidden: boolean;
  ExcludeTeladoc: boolean;
  ExcludeCorehealth: boolean;
}

interface IdsPolicy {
  Id: string;
  CurrentBenYear_Id: string;
  NextBenYear_Id: string;
  HasTeladoc: boolean;
}

interface IdsEmployees {
  Id:string;
  Policy_Id:string;
  BenefitCategory_Id:string;
};

export default KTMScreen(
  { name: "enroll.bencat.record" },
  class extends React.Component<{ bencat_id: string; policy_id: string;  benyear_id?: string }> {
    data: {
      dsBenCat: IdsBenCat;
      dsPolicy: IdsPolicy;
      dsEmployees:IdsEmployees[];
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenCat";
      await KTMApi.start(screen, {
        "@bencat_id": this.props.bencat_id,
      });
      screen.title = this.data.dsBenCat.Name;
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.tabs)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return (
        <RecordHeader
          entityLabel="Benefit Category"
          label={this.data.dsBenCat.Name}
        >
          <Line>
            <Display field={field("Description")} />
            <Display field={field("IsHidden")} />
            {this.data.dsPolicy.HasTeladoc ? (
                <Display field={field("ExcludeTeladoc")} />
            ) : <></>}
            {this.data.dsPolicy.HasTeladoc ? (
                <Display field={field("ExcludeCorehealth")} />
            ) : <></>}
            <div className="RT-Static-Field">
              <label className="RT-Static-Field__label">
                Enrolled Employees 
              </label>
              <div className="RT-Static-Field__content">
                {this.data.dsEmployees.length}
              </div>
            </div>
            <Button action={action(this.edit)} label="Edit" style="outline" />
          </Line>
        </RecordHeader>
      );
    }

    tabs({ field, draw, action }: RenderEvent) {
      return (
        <TabSet>
          <Tab label="Benefits" template={this.benefits_tab} />
        </TabSet>
      );
    }

    benefits_tab({ field, draw, action }: RenderEvent) {
      return (
        <BenefitsScreen
          bencat_id={this.props.bencat_id}
          policy_id={this.props.policy_id}
          benyear_id={this.props.benyear_id}
        />
      );
    }

    next_year_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.next_year_empty)}</Column>
        </Panel>
      );
    }

    next_year_empty({ field, draw, action }: RenderEvent) {
      return (
        <EmptyState
          label="Next year not available for edit yet"
          icon="edit"
        ></EmptyState>
      );
    }

    async edit({ screen }: ActionEvent) {
      await screen.openDialog(
        <EditScreen bencat_id={this.data.dsBenCat.Id} disableIsHidden={this.data.dsEmployees.length > 0}/>,
        { onRecordChanged: this.refresh_data }
      );
    }

    async refresh_data({ screen }: ActionEvent) {
      await screen.restart();
    }
  }
);
