import React from "react";
import Spinner from "components/Spinner";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { KTMScreen } from "ktm-ui";
import { useTable, useSortBy, usePagination } from "react-table";
import { FaSortDown, FaSortUp } from "react-icons/fa";

const ClaimsTable = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Claim Number",
        accessor: "claimNo",
      },
      {
        Header: "Submitted",
        accessor: "claimDate",
        Cell: ({ value }) => moment(value).utc().format("MMM DD, YYYY"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const statusMap = {
            SUBMITTED: "SUBMITTED",
            PENDING: "UNDER REVIEW",
            PAID: "PAID",
            ZEROPAID: "ZEROPAID",
            ADJUDICATED: "PENDING PAYMENT",
          };
          return statusMap[value] || value;
        },
      },
      {
        Header: "Type",
        accessor: "accountType",
      },
      {
        Header: "Claimed ($)",
        accessor: "totalClaimed",
        Cell: ({ row }) =>
          row.original.status === "SUBMITTED" ? "--" : row.original.totalClaimed.toFixed(2),
        className: "text-right",
      },
      {
        Header: "Approved ($)",
        accessor: "totalApproved",
        Cell: ({ row }) =>
          row.original.status === "SUBMITTED" ? "--" : row.original.totalApproved.toFixed(2),
        className: "text-right",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <Link to={`/ops/vieweob?claimId=${row.original.id}`} className="btn btn-primary pd-y-5">
            View EOB
          </Link>
        ),
        className: "text-right",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="table table-hover black">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown />
                      ) : (
                        <FaSortUp />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination" style={{float:"inline-end"}}>
        <button className="btn btn-primary btn-sm" onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
        </button>
        <span style={{fontSize: 17, paddingTop: 4, paddingLeft: 20, paddingRight: 20}}>
          Page {pageIndex + 1} of {pageOptions.length}
        </span>
        <button className="btn btn-primary btn-sm" onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
        </button>
      </div>
    </>
  );
};

class ClaimHistory extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      claims: [],
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      currHsaLimit: 0,
      currHsaBalance: 0,
      currLsaLimit: 0,
      currLsaBalance: 0,
      error: null,
    };
    
    this.startLoading = this.startLoading.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const employeeId = params.get('employeeId');

    if (employeeId) {
      this.startLoading(employeeId);
    } else {
      console.error("Employee ID not found in query string");
      this.setState({ error: "Employee ID not found in query string" });
    }
  }

  async startLoading(employeeId) {
    try {
      const claimsResponse = await axios.get(`/api/claims/getclaims?employeeId=${employeeId}`);
      const { claims, employeeInfo } = claimsResponse.data;

      this.setState({
        loaded: true,
        claims,
        firstName: employeeInfo.firstName,
        lastName: employeeInfo.lastName,
        email: employeeInfo.email,
        company: employeeInfo.company,
        currHsaLimit: employeeInfo.currHsaLimit,
        currHsaBalance: employeeInfo.currHsaBalance,
        currLsaLimit: employeeInfo.currLsaLimit,
        currLsaBalance: employeeInfo.currLsaBalance,
        error: null,
      });
    } catch (error) {
      console.error("Error loading data: ", error);
      this.setState({
        loaded: true,
        error: "Failed to load claim data. Please try again later."
      });
    }
  }

  renderContent() {
    const {
      loaded,
      claims,
      firstName,
      lastName,
      email,
      company,
      currHsaLimit,
      currHsaBalance,
      currLsaLimit,
      currLsaBalance,
      error
    } = this.state;

    if (!loaded) {
      return <Spinner />;
    }

    if (error) {
      return <div className="error">{error}</div>;
    }

    if (claims.length === 0) {
      return <div>No claims found for this employee.</div>;
    }

    return (
      <div 
        className="bd bd-gray-300 rounded table-responsive"
        style={{
          margin: 20,
          backgroundColor: "white",
          padding: 20
        }}>
        <div style={{fontSize: 17, display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 20}}>
          <span style={{ fontSize: 20, fontWeight: "bold", flexGrow:1 }}>Claim History</span>
          <div style={{marginRight:50}}>
            <p><strong>Name:</strong> {firstName} {lastName}</p>
            <p><strong>Email:</strong> {email}</p>
            <p><strong>Company:</strong> {company}</p>
          </div>
          <div>
            <p><strong>HSA Limit:</strong> ${currHsaLimit}</p>
            <p><strong>HSA Balance:</strong> ${currHsaBalance}</p>
            <p><strong>LSA Limit:</strong> ${currLsaLimit}</p>
            <p><strong>LSA Balance:</strong> ${currLsaBalance}</p>
          </div>
        </div>
        <ClaimsTable data={claims} />
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}

export default KTMScreen({ name: "claimhistory" }, ClaimHistory);
