export class ClickThrottle {
    lastClick:number = 0;

    handle(e:React.MouseEvent,handler:()=> any){
        let current = Date.now();
        let lastClick = this.lastClick;
        this.lastClick = current;

        if (lastClick && ((current - lastClick) < 750)){
            e.preventDefault();
            e.stopPropagation();
            console.log("Click ignored");
            return;
        }
        handler();
    }
}