import React from "react";
import Spinner from "components/Spinner";
import moment from "moment";
import axios from "axios";
import CustomToolTip from "../components/CustomToolTip";
import toastr from "toastr";
import { KTMScreen } from "ktm-ui";
import SubmittedImages from "./SubmittedImages";

export default KTMScreen(
  { name: "vieweob" },
  class extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        loaded: false,
        claim: null,
        expenses: null,
        images: null,
        paymentMethod: null,
        opsUser: null,
        adj: null,
        emp: null,
        company: null,
        sourceListDisplay: null,
        paymentInfo: null,
        paymentDate: null,
      };
    }

    componentDidMount() {
      const { loaded } = this.state;
      if (!loaded) {
        this.startLoading();
      }
    }

    startLoading = async () => {
      try {
        const response = await axios.get(`/api/claims/getclaimeob?claimId=${this.props.claimId}`);
        const {
          claim, expenses, images, ops, adj, emp, company, sourceListDisplay,
          paymentMethod, paymentInfo, paymentDate,
        } = response.data;

        this.setState({
          loaded: true,
          claim,
          expenses,
          images,
          opsUser: ops,
          adj,
          emp,
          company,
          sourceListDisplay,
          paymentMethod: paymentMethod || "",
          paymentInfo: paymentInfo || "",
          paymentDate: paymentDate || "",
        });
      } catch (error) {
        toastr.error("Failed to grab the employee information for the claim", "Error");
      }
    };

    renderExpenseList = (expenses) => {
      const hideExpenseLineMsg = "Hide Expense Line";
      return expenses?.map((item) => {
        let noExpenseAttached = false;
        const expenseNotes = item.details.map((detail) => {
          let msg = "";
          if (!noExpenseAttached && detail.messageCode && detail.shortDescription === hideExpenseLineMsg) {
            noExpenseAttached = true;
          }
          if (detail.messageCode) {
            msg = `(${detail.messageCode})`;
          }
          return <li key={detail.id}>{detail.messageText} {msg}</li>;
        });

        return !noExpenseAttached && (
          <React.Fragment key={item.id}>
            <tr>
              <td>{item.treatmentDate && moment(item.treatmentDate).utc().format("MMM DD, YYYY")}</td>
              <td>{item.patient}</td>
              <td>{item.expenseDesc}</td>
              <td className="text-right">{item.expenseAmount.toFixed(2)}</td>
              <td className="text-right">{item.claimedAmount.toFixed(2)}</td>
              <td className="text-right">{item.coverageRate.toFixed(0)}</td>
              <td className="text-right">{item.approvedAmount.toFixed(2)}</td>
            </tr>
            {item.details.length > 0 && (
              <tr>
                <td colSpan={7}>
                  <ul>{expenseNotes}</ul>
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      });
    };

    renderContent = () => {
      const {
        loaded,
        claim,
        expenses,
        paymentMethod,
        adj,
        emp,
        company,
        sourceListDisplay,
        paymentInfo,
        paymentDate,
        opsUser,
        images
      } = this.state;

      if (!loaded) {
        return <Spinner />;
      }

      const expenseList = this.renderExpenseList(expenses);

      let status = "";
      if (claim.status === "PAID") {
        status = paymentMethod === "EFT" ? "PAID EFT" : paymentMethod === "CHEQUE" ? "PAID CHQ" : "PAID";
      } else if (claim.status === "SUBMITTED") {
        status = "SUBMITTED";
      } else if (claim.status === "PENDING") {
        status = "UNDER REVIEW";
      } else if (claim.status === "ZEROPAID") {
        status = "ZEROPAID";
      } else if (claim.status === "ADJUDICATED") {
        status = "PENDING PAYMENT";
      }

      let desc = "";
      const paymentText = paymentMethod === "EFT"
        ? ". Payment was made by electronic funds transfer to your bank account and may take up to 3 business days to arrive."
        : paymentMethod === "CHEQUE"
        ? ". Payment was made by cheque and it should arrive in the mail in 3-5 business days."
        : ".";

      if (claim.status === "PAID") {
        desc = `This claim was processed for payment on ${paymentDate?.toString().replace("T00:00:00", "")}${paymentText}`;
        if (claim.disbursementId) {
          desc += ` The payment reference is: ${claim.disbursementId}.`;
        }
        if (paymentMethod === "CHEQUE") {
          desc += ` The cheque no: ${paymentInfo}.`;
        }
      } else if (claim.status === "ZEROPAID") {
        desc = "Your claim has been processed with no reimbursement approved. Please read our claim notes to see if the expense(s) you submitted are eligible for resubmission before reaching out to our support team.";
      } else if (claim.status === "SUBMITTED") {
        desc = "Please allow up to 5 business days for review. You will receive an email once your claim has been processed.";
      } else if (claim.status === "PENDING") {
        desc = "One of our team members is currently reviewing your claim. Once their review is complete, you will receive an email confirmation.";
      } else if (claim.status === "ADJUDICATED") {
        desc = "PENDING PAYMENT - Your claim has been processed and your reimbursement payment is in the queue.";
      }

      const adjudicator = adj && opsUser && ["ADJUDICATED", "PAID", "ZEROPAID"].includes(claim.status) && (
        <div className="row">
          <div className="col-sm-8">
            Adjudication finalized by: <b>{adj.username}</b> on <b>{claim.adjudicatedAt.replace("T00:00:00", "")}</b>
          </div>
        </div>
      );

      const dataTable = claim.status !== "SUBMITTED" && claim.status !== "PENDING" && (
        <div className="br-pagebody">
          <div className="br-section-wrapper no-padding mg-x-15 mg-b-30">
            <div className="bd rounded table-responsive">
              <table className="table" style={{ marginBottom: 0, backgroundColor: "white" }}>
                <thead>
                  <tr>
                    <th>Service Date</th>
                    <th>Patient</th>
                    <th>Type</th>
                    <th className="text-right">Expense ($)</th>
                    <th className="text-right">Claimed ($)</th>
                    <th className="text-right">Coverage (%)</th>
                    <th className="text-right">Approved ($)</th>
                  </tr>
                </thead>
                <tbody>{expenseList}</tbody>
              </table>
            </div>
          </div>
        </div>
      );

      return (
        <div style={{padding: 30, margin: 20, backgroundColor: "white"}}>
          <div className="herder" style={{display:"flex", flexDirection: "row", justifyContent:"space-between", marginBottom: 45, marginTop: 25}}>
            <div style={{fontSize: 20, fontWeight:"bold"}}>
                CLAIM {claim.claimNo} {" (" + claim.accountType + ")"} {" - "}
                {emp.firstName} {emp.lastName} {"(" + company.name + ")"}
            </div>
            <div>
              <button className="btn btn-primary" onClick={() => window.location.href = "/ops/claimhistory?employeeId="+emp.id}>
                View Claim History
              </button>
            </div>
          </div>
          <div>
            <div style={{ padding:20, fontSize:17}}>
              <div style={{ marginTop: "15px", marginBottom: "15px" ,display:"flex", flexDirection:"row"}}>
                <div className="col-md-6">
                  <dl className="row claim-width-custom">
                    <dt className="col-sm-3 mg-b-5">
                      Claim Created
                      <CustomToolTip
                        header="Claim Created"
                        bodyContent="This is the date the claim number was created."
                      />
                    </dt>
                    <dd className="col-sm-9 mg-b-0">
                      {moment(claim.claimDate).utc().format("MMM DD, YYYY")}
                    </dd>
                    <dt className="col-sm-3 mg-b-5">Claim Status</dt>
                    <dd className="col-sm-9 mg-b-0">
                      <span className="text-capitalize">{status}</span>
                    </dd>
                    <dt className="col-sm-3 mg-b-5">Type</dt>
                    <dd className="col-sm-9 mg-b-0">
                      <span className="text-capitalize">{sourceListDisplay}</span>
                    </dd>
                  </dl>
                </div>
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <span style={{fontWeight:"bold"}}>Total amounts for this summary</span>
                  <dl className="row text-sm-left text-md-right">
                    <dt className="col-sm-4 col-md-10 mg-b-5">Claimed amount ($)</dt>
                    <dd className="col-sm-8 col-md-2 mg-b-0">
                      {claim.status === "SUBMITTED" ? "PENDING" : claim.totalClaimed.toFixed(2)}
                    </dd>
                    <dt className="col-sm-4 col-md-10 mg-b-5">Approved to pay ($)</dt>
                    <dd className="col-sm-8 col-md-2 mg-b-0">
                      {claim.status === "SUBMITTED" ? "PENDING" : claim.totalApproved.toFixed(2)}
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-10">{desc}</div>
              </div>
              <br/>
              {adjudicator}
            </div>
          </div>
          {dataTable}
          <div style={{ width: "100%" }}>
            <SubmittedImages type="LSA" images={images} />
          </div>
          <div style={{fontSize:17, padding: 30}}>
            <div>
                  If you have any questions about your claim benefit summary, please call National HealthClaim at (866) 342-5908 or email us at <a href="mailto:support@nationalhealthclaim.com" style={{color: "#4987ef"}}>support@nationalhealthclaim.com</a>.
            </div>
            <div style={{marginTop:20}}>
              <button className="btn btn-primary print-button" onClick={() => window.print()}>
                <i className="fa fa-print mg-r-10" /> Print Summary
              </button>
            </div>
          </div>
        </div>
      );
    };

    render() {
      return <>{this.renderContent()}</>;
    }
  }
);
